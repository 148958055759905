<div class="builderCont">
  <div class="cont">
    <div class="tabCont">
      @if (fullResponce().id !== undefined) {
      <div class="deploy">
        @if (!fullResponce().allreadyOpened) {
        <!--         <button
          class="btn btn-sm action_button permissionButton"
          (click)="
            SocketService.socket_emit(
              fullResponce().edited_document,
              'access_update_doc'
            )
          "
        >
          Ask permision
        </button> -->
        }
      </div>
      }

      <div class="langNamesCont">
        <!-- {{disabledLang}} -->
        <!-- <div class="disabledLangs" *ngIf="disabledLang"></div> -->
        @for (item of languageFormObj.langData | keyvalue : returnZero; track item.key) {
        <div>
          @if (item.key !== 'selected') {
          <div
            class="title5 langItem"
            [ngClass]="{ selectedLang: languageFormObj.selected == item.key }"
            (click)="selectLangTab(item.key)"
          >
            {{ item.key }}
          </div>
          }
        </div>
        }
      </div>

      <!-- <div id="formio">
        <formio [form]="dump">
        </formio>
      </div> -->

      <div class="cms_data">
        <formio
          [form]="languageFormObj.langData[languageFormObj.selected].schema"
          (change)="OnChange($event)"
          (errorChange)="errors($event)"
          (submit)="onSubmitTheForm($event, languageFormObj.selected)"
          [submission]="languageFormObj.langData[languageFormObj.selected].submissionData"
          (ready)="ready($event)"
          [refresh]="triggerRefresh"
          (customEvent)="customEvent($event)"
        >
        </formio>
      </div>
    </div>
  </div>
</div>